import * as React from 'react';
import { trackPage } from '../lib/track/track';
import * as PageContentStyles from '../_scss/modules/_page-content.module.scss';
import DynamicComponent from '../components/_common/dynamic-component/dynamic-component';
import { useStoryBlockCommon, useStoryblok } from '../lib/storyblok/storyblok';
import { PageHomeStoryblok } from '../_shared/interfaces/storyblok';
import SEO from '../components/_common/seo/seo';
import Layout from '../components/_common/_layout/layout';
import { IntlProvider } from 'react-intl';
import { AppThemeProvider } from '../context/app-theme/app-theme.context';
import { ModalContextProvider } from '../context/modal/modal.context';
import { PageAccountStoryblokProvider } from '../context/storyblok/page-account-storyblok.context';

const PageHome = ({ pageContext }: { pageContext: { story: { content: PageHomeStoryblok; full_slug: string } } }) => {
    let story = pageContext.story;
    story = useStoryblok(story);
    const storyblokCommon = useStoryBlockCommon(story.full_slug);

    const header = story.content.header.map((blok) => {
        return <DynamicComponent blok={blok} key={blok._uid} storyblokCommon={storyblokCommon} />;
    });

    const components = story.content.body.map((blok) => {
        return <DynamicComponent blok={blok} key={blok._uid} storyblokCommon={storyblokCommon} />;
    });

    let componentsDefault;
    if (story.content.body_default) {
        componentsDefault = story.content.body_default.map((blok) => {
            return <DynamicComponent blok={blok} key={blok._uid} storyblokCommon={storyblokCommon} />;
        });
    }

    React.useEffect(() => {
        trackPage({
            country: process.env.GATSBY_COUNTRY,
            language: storyblokCommon.configuration.content.language,
            title: story.content.metadata.title,
            template: 'Home',
        });
    }, []);

    return (
        <AppThemeProvider storyblokCommon={storyblokCommon}>
            <IntlProvider locale={storyblokCommon.configuration.content.language}>
                <PageAccountStoryblokProvider blok={storyblokCommon.accountDetailPage.content}>
                    <ModalContextProvider>
                        <Layout paddingDisabled={true} storyblokCommon={storyblokCommon}>
                            <SEO
                                metadata={story.content.metadata}
                                config={storyblokCommon.configuration.content}
                                full_slug={story.full_slug}
                            />
                            {header}
                            {components}
                            {componentsDefault && (
                                <div className={[PageContentStyles.pageContentStandard, PageContentStyles.followUp].join(' ')}>
                                    {componentsDefault}
                                </div>
                            )}
                        </Layout>
                    </ModalContextProvider>
                </PageAccountStoryblokProvider>
            </IntlProvider>
        </AppThemeProvider>
    );
};

export default PageHome;
