import React, { createContext, FC, ReactElement, useContext } from 'react';
import { StoryblokCommon } from '../../lib/storyblok/storyblok';

export type AppTheme = 'hs' | 'dr';

const Context = createContext<AppTheme>('hs');

export const useAppTheme = () => useContext(Context);
type Props = {
    children: ReactElement<any> | ReactElement<any>[];
    storyblokCommon: StoryblokCommon;
};
export const AppThemeProvider: FC<Props> = ({ children, storyblokCommon }) => {
    const {
        content: { theme = 'hs' },
    } = storyblokCommon.configuration ?? {};

    return <Context.Provider value={theme}>{children}</Context.Provider>;
};
